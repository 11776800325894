import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CommonModule} from "@angular/common";
import {RouterOutlet} from "@angular/router";
import {HomeComponent} from "./pages/home/home.component";
import {SharedModule} from "./shared/shared.module";
import {ContactComponent} from "./pages/contact/contact.component";
import {HeroHomeComponent} from "./pages/home/hero-home/hero-home.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NotAuthorizedComponent} from "./pages/errors/not-authorized/not-authorized.component";
import {NotFoundComponent} from "./pages/errors/not-found/not-found.component";
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {getFunctions, provideFunctions} from '@angular/fire/functions';
import {getStorage, provideStorage} from '@angular/fire/storage';

import {environment} from "../environments/environment";
import {DevLoginComponent} from "./dev-login/dev-login.component";
import {AdsenseModule} from "ng2-adsense";

@NgModule({
  declarations: [
    AppComponent,
    HeroHomeComponent,
    HomeComponent,
    ContactComponent,
    NotAuthorizedComponent,
    NotFoundComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    RouterOutlet,
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DevLoginComponent,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAuthModule,
    // AngularFirestoreModule,
    // AngularFireStorageModule,
    AdsenseModule.forRoot(environment.adsense)
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    UserTrackingService,
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
