<div *ngIf="!isDevLogged" class="login-overlay d-flex flex-column justify-content-center align-items-center">
  <p class="text-warning text-center w-75">
    Welcome to the development version of my application. <br>
    Please be aware that this environment is primarily intended for testing and development purposes. <br>
    As a result, you may encounter various issues, including but not limited to incomplete features, bugs, and unexpected behavior.
  </p>
  <div class="d-flex flex-column">
    <input type="text" id="username" [(ngModel)]="username" placeholder="username" required>
    <input type="password" id="password" [(ngModel)]="password" placeholder="password" required>
    <button (click)="login()">Login</button>
  </div>
</div>
