import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {ContactComponent} from "./pages/contact/contact.component";
import {NotAuthorizedComponent} from "./pages/errors/not-authorized/not-authorized.component";
import {NotFoundComponent} from "./pages/errors/not-found/not-found.component";

export const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent, title: 'Home | CloudTools'},
  {path: 'tools', loadChildren: () => import('./pages/tools/tools.module').then(m => m.ToolsModule)},
  {path: 'contact', component: ContactComponent, title: 'Contact | CloudTools'},
  {path: '401', component: NotAuthorizedComponent, title: '401 Not Authorised | CloudTools'},
  {path: '**', component: NotFoundComponent, title: '404 Not Found | CloudTools'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
