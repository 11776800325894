<!--<div *ngIf="isDevLogged" class="home-purple-gradient" [class.mobile-menu-visible]="utilsService.openMobileMenus">-->
<div class="home-purple-gradient" [class.mobile-menu-visible]="utilsService.openMobileMenus">
  <app-header/>
  <router-outlet></router-outlet>
<!--  <app-partners/>-->
  <app-footer/>
</div>

<!--<dev-login (loginStateChange)="onLoginStateChange($event)"></dev-login>-->
<!-- back to top -->
<app-back-to-top/>
<!-- back to top -->
