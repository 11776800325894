import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EmailService} from "../../shared/services/email.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactComponent implements OnInit {

  public documentForm!: FormGroup;
  public formSubmitted = false;
  public messageSent: boolean = false;
  public errorSent: boolean = false;

  constructor(
    private emailService: EmailService
  ) {
  }


  ngOnInit() {
    this.documentForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      message: new FormControl(null, Validators.required),
    })
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.documentForm.valid) {
      const name = this.documentForm.value.name;
      const email = this.documentForm.value.email;
      const message = this.documentForm.value.message;

      const subjectToReceiver = 'CloudTools: ' + name + ' sent you a message through the contact form';
      const bodyToReceiver =
        `Name: ${name}
        Email: ${email}

        ${message}`;

      const subjectToSender = 'CloudTools: Thank you for contacting us!';
      const bodyToSender =
        `Thank you ${name} for contacting us with your question.

        We will get back to you as soon as possible!
        If you did not send us a message, please let us know by responding to this email.

        Best regards,
        Team CloudTools`;

      // Sending email to receiver
      this.emailService.sendEmail('support@cloudkit.tools', subjectToReceiver, bodyToReceiver).subscribe({
        next: () => {
          this.emailService.sendEmail(email, subjectToSender, bodyToSender).subscribe({
            error: err => console.error('Error while sending email to sender:', err)
          });
          this.messageSent = true;
          this.documentForm.reset();
          this.formSubmitted = false;
        },
        error: (err: any) => {
          this.errorSent = true;
          console.error('Error while sending email to receiver:', err)
          this.formSubmitted = false;
        }
      });
    }
  }

  get name() {
    return this.documentForm.get('name')
  }

  get email() {
    return this.documentForm.get('email')
  }

  get message() {
    return this.documentForm.get('message')
  }
}
