import {Component, EventEmitter, Output} from '@angular/core';
import { environment } from '../../environments/environment';
import sha256 from 'crypto-js/sha256';
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";

@Component({
  selector: 'dev-login',
  standalone: true,
  imports: [
    FormsModule,
    NgIf
  ],
  templateUrl: './dev-login.component.html',
  styleUrl: './dev-login.component.scss'
})
export class DevLoginComponent {
  @Output() loginStateChange = new EventEmitter<boolean>();

  username: string = '';
  password: string = '';
  isDevLogged: boolean = false;

  private readonly validUsernameHash = 'ef260e9aa3c673af240d17a2660480361a8e081d1ffeca2a5ed0e3219fc18567';
  private readonly validPasswordHash = 'f9d35d43770d39092a663e665e82ae1d84a9e0da3d0d10c407acada6a40cd281';

  constructor() {
    if (!environment.production && window.location.hostname !== 'localhost') {
      this.isDevLogged = false;
    } else {
      this.isDevLogged = true;
      this.loginStateChange.emit(this.isDevLogged);
    }
  }

  login() {
    const usernameHash = sha256(this.username).toString();
    const passwordHash = sha256(this.password).toString();

    if (usernameHash === this.validUsernameHash && passwordHash === this.validPasswordHash) {
      this.isDevLogged = true;
      this.loginStateChange.emit(this.isDevLogged);
    } else {
      alert('Invalid credentials');
    }
  }
}
