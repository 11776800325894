import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {UtilsService} from "./shared/services/utils.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  // @ViewChild(DevLoginComponent) loginComponent!: DevLoginComponent;
  // isDevLogged: boolean = false;

  constructor(
    private router: Router,
    public utilsService: UtilsService,
    // private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe({
      next: event => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  // ngAfterViewInit() {
  //   this.isDevLogged = this.loginComponent.isDevLogged;
  //   this.cdr.detectChanges();
  // }
  //
  // onLoginStateChange(isLogged: boolean) {
  //   this.isDevLogged = isLogged;
  //   this.cdr.detectChanges();
  // }
}
