<section class="document-area pt-150 pb-100">
  <div class="container">
    <div class="document-inner-wrap">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title text-center mb-60">
            <h2 class="title">Have Any Questions?</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div *ngIf="messageSent" class="document-form-wrap">
            <h4 class="title text-success text-center">Thank you for contacting us.</h4>
            <p class="text-center">We will get back to you as soon as possible!</p>
          </div>
          <div *ngIf="!messageSent" class="document-form-wrap">
            <h4 class="title">Get In Touch Now! <span *ngIf="errorSent" class="text-danger">An error occurred while sending!</span></h4>
            <form [formGroup]="documentForm" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-grp">
                    <input type="text" placeholder="Your Name" formControlName="name">
                    <span *ngIf="name?.hasError('required') && (name?.touched || formSubmitted)" class="text-danger">
											Name is required
										</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-grp">
                    <input type="email" placeholder="Your Email" formControlName="email">
                    <span
                      *ngIf="(email?.hasError('required') && email?.touched) || (email?.hasError('email') && email?.dirty) || (formSubmitted && !email?.value)"
                      class="text-danger">
											Email is required and must be a valid email address
										</span>
                  </div>
                </div>
              </div>
              <div class="form-grp">
                <textarea name="message" placeholder="Enter you message....." formControlName="message"></textarea>
                <span *ngIf="message?.hasError('required') && (message?.touched || formSubmitted)" class="text-danger">Message is required </span>
              </div>
              <div class="text-center">
                <button *ngIf="!formSubmitted" type="submit" class="btn">Send Message</button>
                <button *ngIf="formSubmitted" type="button" class="btn pulse-animation" disabled>Sending...</button>
              </div>
            </form>
          </div>
        </div>
<!--        <div class="col-lg-4">-->
<!--          <div class="document-wrap">-->
<!--            <h4 class="title">Read Documents</h4>-->
<!--            <ul class="list-wrap">-->
<!--              <li>-->
<!--                <a href="javascript:void(0)">-->
<!--                  <span class="icon"><i class="fas fa-file-pdf"></i></span>-->
<!--                  Whitepaper-->
<!--                </a>-->
<!--              </li>-->
<!--              <li>-->
<!--                <a href="javascript:void(0)">-->
<!--                  <span class="icon"><i class="fas fa-file-pdf"></i></span>-->
<!--                  Token Sale Terms-->
<!--                </a>-->
<!--              </li>-->
<!--              <li>-->
<!--                <a href="javascript:void(0)">-->
<!--                  <span class="icon"><i class="fas fa-file-pdf"></i></span>-->
<!--                  Presentation-->
<!--                </a>-->
<!--              </li>-->
<!--              <li>-->
<!--                <a href="javascript:void(0)">-->
<!--                  <span class="icon"><i class="fas fa-file-pdf"></i></span>-->
<!--                  Lightpaper-->
<!--                </a>-->
<!--              </li>-->
<!--            </ul>-->
<!--            <a href="javascript:void(0)" class="btn">Download All</a>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</section>
