import { Component } from '@angular/core';
import {UtilsService} from "../../../shared/services/utils.service";

@Component({
  selector: 'app-hero-home',
  templateUrl: './hero-home.component.html',
  styleUrl: './hero-home.component.scss'
})
export class HeroHomeComponent {

  constructor(private utilsService: UtilsService) { }

  scrollToFragment(page: string, fragment: string): void {
    this.utilsService.scrollToFragment(page, fragment);
  }
}
