<div class="hero-wrapper hero-2">
  <div class="hero-bg-gradient1"></div>
  <div class="hero-bg-gradient2"></div>
  <div class="hero-gradient-ball alltuchtopdown"></div>
  <div class="ripple-shape">
      <span class="ripple-1"></span>
      <span class="ripple-2"></span>
      <span class="ripple-3"></span>
      <span class="ripple-4"></span>
      <span class="ripple-5"></span>
  </div>
  <div class="container">
      <div class="hero-style2">
          <div class="row">
              <div class="col-lg-12">
                  <h6 class="hero-subtitle">EXPLORE NEW SOLUTIONS</h6>
                  <h1 class="hero-title">CloudTools</h1>
                  <div class="btn-wrap">
                      <h3 class="hero-title2">ToolKIT for everyone</h3>
                      <div class="hero-content">
                          <p class="hero-text">Our goal is provide universal tools for everyone, <br> with many features available <b class="text-nowrap"> free of charge</b>.</p>
                          <a (click)="scrollToFragment('/home','faq')" class="btn btn3" >Find out more</a>
                      </div>
                  </div>
              </div>

          </div>
      </div>
  </div>
</div>
